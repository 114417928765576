
















































































import { Component, Vue } from 'vue-property-decorator'
import CustomerInfo from '@/components/desktop-pos/customer-info.vue'
import CartItems from '@/components/desktop-pos/cart-items.vue'
import PriceSummary from '@/components/desktop-pos/price-summary.vue'
import MemberCard from '@/components/desktop-pos/member-card.vue'
import Receipt from '@/components/Receipt.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import DesktopPosNavbar from '@/components/desktop-pos/navbar.vue'

@Component({
    components: {
        CustomerInfo,
        CartItems,
        PriceSummary,
        MemberCard,
        Receipt,
        DesktopPosNavbar
    },
    computed: {
        ...mapState({
            selectBranch: (state: any) => state.Store.selectBranch,
            selectPos: (state: any) => state.Store.selectPos
        }),
        ...mapGetters({
            net: 'Cart/net',
            memberTel: 'Cart/tel',
            payment: 'Cart/payment'
        })
    },
    methods: {
        ...mapActions({
            previewReceipt: 'Order/previewReceipt',
            createOrder: 'Cart/createOrder',
            setCashAmount: 'Cart/setCashAmount'
        })
    }
})
export default class CardCheckout extends Vue {
  net!: any

  payment!: any

  previewReceipt!: any

  preReceipt: any = null

  selectBranch!: any

  selectPos!: any

  memberTel!: any

  createOrder!: any

  showPriceFormat!: any

  loading = false

  async mounted (): Promise<any> {
      this.preReceipt = await this.previewReceipt({ status: 'no_print', ip: 'no print' })
  }

  async checkout (): Promise<void> {
    this.loading = true
    const result: {
      success: boolean
      message: string
    } = await this.createOrder()
    this.loading = false
    if (result.success) {
      this.$buefy.dialog.confirm({
        message: 'Create Order success',
        canCancel: false,
        onConfirm: () => this.$router.push({ name: 'OrderSuccess' })
      })
    } else {
      let message = ''
      if (result.message.includes('[00]')) {
        message += 'ของไม่พอ : '
      }
      const splitMessage = result.message.split(' - ')
      message += splitMessage[splitMessage.length - 1]
      message += '<br />กลับไปลบของในตะกร้าออกก่อน หรือ ติดต่อผู้จัดการสาขา'
      this.$buefy.dialog.alert({
        title: 'เกิดปัญหา',
        message
      })
    }
  }

  get sumAmount (): string {
    if (!this.preReceipt) return '-'
    return `${this.preReceipt.items.reduce((sum: number, d: any) => sum + d.amount, 0) }`
  }
}
